//console.cloud.google.com/logs/query;query=resource.type%3D%22cloud_function%22%0Aresource.labels.function_name%3D%22analytics_v2-query%22%0Aresource.labels.region%3D%22us-central1%22%0AjsonPayload.logType%3D%22CLICKHOUSE_QUERY%22%0AjsonPayload.logId%3D%22b68fEWtU0S%22;cursorTimestamp=2024-05-01T09:32:14.260714321Z;duration=PT15M?project=affilimate-dev
import { compact, mapKeys } from 'lodash';
import { Timestamp } from '../domainTypes/time';
import * as ENVS from '../env.json';

const BASE = 'https://console.cloud.google.com/logs/query;query=';

export const toLogLink = (
  jsonPayloadQueryArgs: { [key: string]: string },
  otherQueryArgs?: { [key: string]: string },
  timing: {
    aroundTime?: Timestamp;
    duration?: string; // ISO 8601 duration string, e.g. P1D, PT1H
  } = {}
) => {
  const projectId = ENVS.firebase.projectId;

  const allArgs = {
    ...mapKeys(jsonPayloadQueryArgs, (_, k) => `jsonPayload.${k}`),
    ...otherQueryArgs
  };

  const argsStr = encodeURIComponent(
    Object.entries(allArgs)
      .map(([k, v]) => `${k}="${v}"`)
      .join('\n')
  );
  const timingStr = compact(
    Object.entries(timing).map(([k, v]) => {
      if (!v) {
        return null;
      }
      if (typeof v === 'string') {
        return `${k}=${v}`;
      }
      return `${k}=${v.toDate().toISOString()}`;
    })
  ).join(';');
  const fullStr = [argsStr, timingStr].join(';');
  return `${BASE}${fullStr}?project=${projectId}`;
};

export const toCloudFunctionGen1LogLink = (
  fnName: string | null,
  jsonPayloadQueryArgs: { [key: string]: string },
  otherQueryArgs?: { [key: string]: string }
) => {
  const otherArgs: { [key: string]: any } = {
    'resource.type': 'cloud_function',
    ...otherQueryArgs
  };
  if (fnName) {
    otherArgs['resource.labels.function_name'] = fnName;
  }
  return toLogLink(jsonPayloadQueryArgs, otherArgs);
};

export const toCloudFunctionGen2LogLink = (
  fnName: string | null,
  jsonPayloadQueryArgs: { [key: string]: string } = {},
  otherQueryArgs: { [key: string]: string } = {}
) => {
  const otherArgs: { [key: string]: any } = {
    'resource.type': 'cloud_run_revision',
    ...otherQueryArgs
  };
  if (fnName) {
    otherArgs['resource.labels.service_name'] = fnName
      .toLowerCase()
      .replace(/_/g, '-');
  }
  return toLogLink(jsonPayloadQueryArgs, otherArgs);
};
